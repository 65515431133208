import Header from "../components/Header";
import Footer from "../components/Footer";
import person1 from "../assets/img/person_1.jpg";
import slider1 from "../assets/img/slider_1.jpg";
import slider2 from "../assets/img/slider_2.jpg";
import slider4 from "../assets/img/slider_4.jpg";
import slider5 from "../assets/img/slider_3.jpg";


function Courses() {
    return(
        <>
        <Header />
        <section class="probootstrap-slider flexslider2 page-inner">
    <div class="overlay"></div>
    <div class="probootstrap-wrap-banner">
      <div class="container">
        <div class="row">
          <div class="col-md-8">

            <div class="page-title probootstrap-animate">
              <div class="probootstrap-breadcrumbs">
                <a href="/">Home</a><span>Agents</span>
              </div>
              <h1>Study options</h1>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ul class="slides">
      <li style={{backgroundImage: `url(${slider1})`}}></li>
      <li style={{backgroundImage: `url(${slider4})`}}></li>
      <li style={{backgroundImage: `url(${slider2})`}}></li>
    </ul>
  </section>
  
  <section class="probootstrap-section probootstrap-section-lighter">
    <div class="container">
      <div class="row heading">
        <h2 class="mt0 mb50 text-center">AVAILABLE STUDY OPTIONS INCLUDE:</h2>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Online studies for people outside Lagos and  tight work schedule </h2>
              <p><small></small></p>
              {/* <!--<p><a href="#">View Details</a></p>--> */}
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Professional Programme – Major and Diploma</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Departmental Majors in Entrepreneurship Development</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Education (Special For SSCE Student/Jamb) Classes</h2>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Skill Acquisition</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Short Courses</h2>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="probootstrap-card probootstrap-person text-left">
            <div class="probootstrap-card-media">
              <img src={person1} class="img-responsive" alt="Giant Entrepreneurship College" />
            </div>
            <div class="probootstrap-card-text">
              <h2 class="probootstrap-card-heading mb0">Innovation Department</h2>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="probootstrap-half reverse">
    <div class="image-wrap">
      <div class="image" style={{backgroundImage: `url(${slider5})`}}></div>
    </div>
    <div class="text">
      <p class="mb10 subtitle">Why Choose Us</p>
      <h3 class="mt0 mb40">You Will Love Our Services</h3>
      <p>This is where youths are equipped with technical/vocational skills and graduate who have acquired competencies in specific field and intend to further explore possibilities of self reliance are made to know about feasibility studies, development of business plan, </p>
      <p class="mb50">marketing strategies, product branding, financial management, legal issues, franchising and access to funding are thoroughly dealt  with in this packages.</p>
      <p><a href="/register" class="btn btn-primary mb10">Register</a></p>
    </div>
  </section>
      <Footer />
  <div class="gototop js-top">
    <a href="/" class="js-gotop"><i class="icon-chevron-thin-up"></i></a>
  </div>
    </>
    );
}

export default Courses;