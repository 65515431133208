import Header from "../components/Header";
import Footer from "../components/Footer";
import slider1 from "../assets/img/slider_1.jpg";
import slider2 from "../assets/img/slider_2.jpg";
import slider4 from "../assets/img/slider_4.jpg";
import slider5 from "../assets/img/slider_3.jpg";

function Register() {
    return (
        <>
        <Header />
    <section className="probootstrap-slider flexslider2 page-inner">
    <div className="overlay"></div>
    <div className="probootstrap-wrap-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-8">

            <div className="page-title probootstrap-animate">
              <div className="probootstrap-breadcrumbs">
                <a href="/">Home</a><span>Register</span>
              </div>
              <h1>Fill The form below</h1>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ul className="slides">
      <li style={{backgroundImage: `url(${slider1})`}}></li>
      <li style={{backgroundImage: `url(${slider4})`}}></li>
      <li style={{backgroundImage: `url(${slider2})`}}></li>
    </ul>
  </section>

 
  <section className="probootstrap-section">
    <div className="container">
      <div className="col-lg-8 col-xlg-9 col-md-7" >
                        <div className="row">
                            <div className="card-block" style={{alignContent: `center`}}>
                                <form className="form-horizontal form-material" method="post" action="/api/register2.php" id="register2">
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>First Name</strong></label>
                                        <div className="col-md-12">
                                            <input type="text" name="fname" id="fname"  className="form-control form-control-line" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>Middle Name</strong></label>
                                        <div className="col-md-12">
                                            <input type="text" name="mname" id="mname" className="form-control form-control-line" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>Last Name</strong></label>
                                        <div className="col-md-12">
                                            <input type="text" name="sname" id="sname" className="form-control form-control-line" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12"><strong>Select Programme</strong></label>
                                        <div className="col-sm-12">
                                            <select className="form-control form-control-line" name="prog" id="prog">
                                                <option className="active">SELECT</option>
                                                <option value="Skill Acquisation">Skill Acquisation</option>
                                                <option value="Professional Certificate  Diploma (PCD)">Professional Certificate  Diploma (PCD)</option>
                                                <option value="Professional AD Diploma (PAD)">Professional AD Diploma (PAD)</option>
                                                <option value="Professional Post Graduate Diploma (PPGD).">Professional Post Graduate Diploma (PPGD).</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12"><strong>Select Course</strong></label>
                                        <div className="col-sm-12">
                                            <select className="form-control form-control-line" name="course" id="prog">
                                                <option className="active">SELECT</option>
                                                <option value="Entrepreneurship Psychology">Entrepreneurship Psychology</option>
                                                <option value="Professional Management Psychology ">Professional Management Psychology </option>
                                                <option value="Business Administration">Business Administration</option>
                                                <option value="Hospitality and Event Management">Hospitality and Event Management</option>
                                                <option value="Digital Marketting">Digital Marketting</option>
                                                <option value="Techprenuership">Techprenuership</option>
                                                <option value="Business Consultancy">Business Consultancy</option>
                                                <option value="Business Registration">Business Registration</option>
                                                <option value="Food Week 2019">Food Week 2019</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>date of birth</strong></label>
                                        <div className="col-md-12">
                                                 <input placeholder="   dd/MM/yyyy" name="dob" id="dob" type='date' className="form-control" required/>
                                         </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="example-email" className="col-md-12"><strong>Email</strong></label>
                                        <div className="col-md-12">
                                            <input type="email" name="email" id="email" className="form-control form-control-line tcal" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>Phone No</strong></label>
                                        <div className="col-md-12">
                                            <input type="text" name="phone" id="phone" className="form-control form-control-line" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12"><strong>State of Origin</strong></label>
                                        <div className="col-sm-12">
                                            <select className="form-control form-control-line" name="state" id="state">
                                                <option className="active">Select State</option>
                                                <option value="Abia">Abia</option>
                                                <option value="Adamawa">Adamawa</option>
                                                <option value="Akwa-Ibom">Akwa-Ibom</option>
                                                <option value="Anambara">Anambara</option>
                                                <option value="Bauchi">Bauchi</option>                            
                                                <option value="Bayelsa">Bayelsa</option>
                                                <option value="Benue">Benue</option>
                                                <option value="Bornu">Bornu</option>
                                                <option value="Cross River">Cross River</option>
                                                <option value="Delta">Delta</option>
                                                <option value="Ebonyi">Ebonyi</option>
                                                <option value="Edo">Edo</option>
                                                <option value="Ekiti">Ekiti</option>
                                                <option value="Enugu">Enugu</option>
                                                <option value="Gombe">Gombe</option>
                                                <option value="Imo">Imo</option>
                                                <option value="Jigawa">Jigawa</option>
                                                <option value="Kaduna">Kaduna</option>
                                                <option value="Kano">Kano</option>
                                                <option value="Kastina">Kastina</option>
                                                <option value="Kebbi">Kebbi</option>
                                                <option value="Kogi">Kogi</option>
                                                <option value="Kwara">Kwara</option>
                                                <option value="Lagos">Lagos</option>
                                                <option value="Nasarwa">Nasarwa</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Ogun">Ogun</option>
                                                <option value="Ondo">Ondo</option>
                                                <option value="Osun">Osun</option>
                                                <option value="Oyo">Oyo</option>
                                                <option value="Plateau">Plateau</option>
                                                <option value="Rivers">Rivers</option>
                                                <option value="Sokoto">Sokoto</option>
                                                <option value="Taraba">Taraba</option>
                                                <option value="Yobe">Yobe</option>
                                                <option value="Zamfara">Zamfara</option>
                                                <option value="F.C.T">F.C.T</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>Local Government</strong></label>
                                        <div className="col-md-12">
                                            <input type="text" name="lga" id="lga" className="form-control form-control-line" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12"><strong>Address</strong></label>
                                        <div className="col-md-12">
                                            <input type="text" name="address" id="address" className="form-control form-control-line" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <button className="btn btn-success" type="submit" id="submit" name="submit">Apply</button>
                                        </div>
                                    </div>
                                    <div id="status2"></div>
                                </form>
                            </div>
                        </div>
                    </div>
    </div>
  </section>


  <section className="probootstrap-half reverse">
    <div className="image-wrap">
      <div className="image" style={{backgroundImage: `url(${slider5})`}}></div>
    </div>
    <div className="text">
      <p className="mb10 subtitle">Why Choose Us</p>
      <h3 className="mt0 mb40">AVAILABLE STUDY OPTIONS INCLUDE:</h3>
      <p>7 Intensive weekends – only 13 days of study.</p>
      <p className="mb50">Online studies for people outside Lagos or individuals with tight work schedule. You will complete the entire Professional Training Programme within few days.  Visit www.gec.africa.com.</p>
      <p><a href="/" className="btn btn-primary mb10">Apply</a></p>
    </div>
  </section>

        <Footer />

  <div className="gototop js-top">
    <a href="/" className="js-gotop"><i className="icon-chevron-thin-up"></i></a>
  </div>
        </>
    );
}

export default Register;