import Header from "../components/Header";
import Footer from "../components/Footer";
import slider1 from "../assets/img/slider_1.jpg";
import slider2 from "../assets/img/slider_2.jpg";
import slider4 from "../assets/img/slider_4.jpg";

function Contact() {
    return (
    <>
    <Header />
        <section class="probootstrap-slider flexslider2 page-inner">
    <div class="overlay"></div>
    <div class="probootstrap-wrap-banner">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="page-title probootstrap-animate">
              <div class="probootstrap-breadcrumbs">
                <a href="/">Home</a><span>About</span>
              </div>
              <h1>Feel free to contact us</h1>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ul class="slides">
      <li style={{backgroundImage: `url(${slider1})`}}></li>
      <li style={{backgroundImage: `url(${slider4})`}}></li>
      <li style={{backgroundImage: `url(${slider2})`}}></li>
    </ul>
  </section>

  
  <section class="probootstrap-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <form action="#" method="post" class="probootstrap-form mb60">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="fname">First Name</label>
                  <input type="text" class="form-control" id="fname" name="fname" required/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lname">Last Name</label>
                  <input type="text" class="form-control" id="lname" name="lname" required/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" name="email" required/>
            </div>
            <div class="form-group">
              <label for="message">Message</label>
              <textarea cols="30" rows="10" class="form-control" id="message" name="message"></textarea>
            </div>
            <div class="form-group">
              <input type="submit" class="btn btn-primary" id="submit" name="submit" value="Send Message" required/>
            </div>
          </form>
        </div>
        <div class="col-md-3 col-md-push-1">
          <h4>Contact Details</h4>
          <ul class="with-icon colored">
            <li><i class="icon-location2"></i> <span>36/40 Old Ojo Road (top floor) Opposite Zenith Bank Plc, 1st Gate Maza-Maza, Amuwo Kuje, Lagos State</span></li>
            <li><i class="icon-mail"></i><span>info@gecafrica.com</span></li>
            <li><i class="icon-phone2"></i><span>+234 803 424 4200</span></li>
          </ul>

        </div>
      </div>
    </div>
  </section>  
  <div id="map"></div>
      <Footer />

      <div class="gototop js-top">
    <a href="/" class="js-gotop"><i class="icon-chevron-thin-up"></i></a>
  </div>

  {/* <!-- Google Map --> */}
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false"></script>
        </>
    );
}

export default Contact;