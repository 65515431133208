import logo from "../assets/img/logo.jpg";

function Header() {
    return(
        <>
  <div className="probootstrap-loader"></div>

  <header role="banner" className="probootstrap-header">
    <div className="container">
        <a href="/" className="probootstrap-logo"><img src={logo} style={{width: "70%", height: "40%"}} alt="GEC" /></a>

        <a href="javascript:void(0);" className="probootstrap-burger-menu visible-xs" ><i>Menu</i></a>
        <div className="mobile-menu-overlay"></div>

        <nav role="navigation" className="probootstrap-nav hidden-xs">
          <ul className="probootstrap-main-nav">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/courses">Courses</a></li>
            <li><a href="/register">Apply</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
          <div className="extra-text visible-xs"> 
            <a href="javascript:void(0);" className="probootstrap-burger-menu"><i>Menu</i></a>
            <h5>Address</h5>
            <p>36/40 Old Ojo Road (top floor) Opposite Zenith Bank Plc, 1st Gate Maza-Maza, Amuwo Kuje, Lagos State</p>
            <h5>Connect</h5>
            <ul className="social-buttons">
              <li><a href="#"><i className="icon-twitter"></i></a></li>
              <li><a href="#"><i className="icon-facebook2"></i></a></li>
              <li><a href="#"><i className="icon-instagram2"></i></a></li>
            </ul>
          </div>
        </nav>
    </div>
  </header>
        </>
    );
}

export default Header;