import Header from "../components/Header";
import Footer from "../components/Footer";
import slider1 from "../assets/img/slider_1.jpg";
import slider2 from "../assets/img/slider_2.jpg";
import slider4 from "../assets/img/slider_4.jpg";
import slider5 from "../assets/img/slider_3.jpg";

function About() {
    return (
        <>
        <Header />
        <section className="probootstrap-slider flexslider2 page-inner">
    <div className="overlay"></div>
    <div className="probootstrap-wrap-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-8">

            <div className="page-title probootstrap-animate">
              <div className="probootstrap-breadcrumbs">
                <a href="/">Home</a><span>About</span>
              </div>
              <h1>About Us</h1>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ul className="slides">
      <li style={{backgroundImage: `url(${slider1})`}}></li>
      <li style={{backgroundImage: `url(${slider4})`}}></li>
      <li style={{backgroundImage: `url(${slider2})`}}></li>
    </ul>
  </section>
  

  <section className="probootstrap-section">
    <div className="container">
      <div className="row heading">
        <div className="col-md-12"><h2 className="mt0 mb50 text-center">Our Company</h2></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* <!--<p><img src="img/slider_5.jpg" className="img-responsive" alt="Free Bootstrap Template by uicookies.com"></p>--> */}
          <p>Giant Entrepreneurship College is a Business School for the development of ideas, innovations, and a source of knowledge and skills to improve productivity. To harness appropriate educational resources to develop and train the massive pool of youths, unemployed graduates, students, adults and business owners across the country. Also to produce a generation of brilliant, well trained entrepreneurship professionals to render highest professional service to small and medium scale business community.  GEC BUSINESS SCHOOL is a trading name of Giant Entrepreneurship College which was established in 2017 ,registered by Cooperate Affairs Commission (CAC) and approved by Federal Government (FG) . GEC BUSINESS SCHOOL is in relationship with Institute For Chartered Entrepreneurship Consultant Of Nigeria and Nevada Business School. GEC BUSINESS SCHOOL develops ideas, innovations and a source of inspiring business knowledge and skills to increase productivity. GEC BUSINESS SCHOOL has trained a massive pool of youths, unemployed graduates, students, adults and business owners across Africa and Asia. GEC BUSINESS SCHOOL has produced a huge number of brilliant, well trained entrepreneurship professionals who are currently rendering the highest professional service to small and medium scale businesses in Nigeria . GEC BUSINESS SCHOOL is set to rebuild the Nations economic value system by transforming individuals into creators of sustainable wealth. </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2>Our Mission</h2>
          <p>To rebuild Nation’s economic value system by transforming individuals into creators of sustainable wealth.</p>
        </div>
        <div className="col-md-6">
          <h2>Our Vision</h2>
          <p>To promote and achieve independence by providing knowledge and skills for job creation and National Development.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="probootstrap-half">
    <div className="image-wrap">
      <div className="image" style={{backgroundImage: `url(${slider2})`}}></div>
    </div>
    <div className="text">
      {/* <!--<p className="mb10 subtitle">Know Us More</p>--> */}
      <h3 className="mt0 mb40">GOING FORWARD</h3>
      <p>The college is in relationship with the institute for Chartered Entrepreneurship Consultants of Nigeria.</p>
      <p className="mb50">The Post Graduate School of Entrepreneurship Management (PSEM) of the college is affiliated with the institute and this joint working relationship will benefit our students.</p>
      <p><a href="/Register" className="btn btn-primary mb10">Apply</a></p>
    </div>
  </section>
  <section className="probootstrap-half reverse">
    <div className="image-wrap">
      <div className="image" style={{backgroundImage: `url(${slider5})`}}></div>
    </div>
    <div className="text">
      <p className="mb10 subtitle">Why Choose Us</p>
      <h3 className="mt0 mb40">PROGRAMME COLLABORATION</h3>
      <p>With the Institute for Chartered Entrepreneurship Consultants of Nigeria www.icenigeria.com , once a student has successfully completed the specialised Advanced Diploma as well as specialised Post Graduate Diploma in Psychology of Entrepreneurship Management. It is taken under the joint project partnership with the institute</p>
      <p className="mb50">institute that the student has passed part one, two and three of the Institute for Chartered Entrepreneurship Consultants of Nigeria Professional Membership qualifying examination. Thus, when the student becomes “Associate Member” of the institute, he or she is subject to payment of the institute prescribed fees</p>
      <p><a href="contact.php" className="btn btn-primary mb10">Find out more</a></p>
    </div>
  </section>
   <section className="probootstrap-half">
    <div className="image-wrap">
      <div className="image" style={{backgroundImage: `url(${slider1})`}}></div>
    </div>
    <div className="text">
      <p className="mb10 subtitle">Certifications</p>
      <h3 className="mt0 mb40">SPECIAL ACCELERATED CERTIFICATION</h3>
      GEC BUSINESS SCHOOL promotes and achieve independence by providing business knowledge and skill for job creation and National Development. 
      <p>This is a special Accelerated Certification Project designed to help unlicensed consultants, business advisers, business managers, other practitioners, and university graduates in Management, Economics, Accounting Advertisement, Marketing and Matured non-graduates.</p>
      <p className="mb50">Obtain practice licences and acquire globally competitive skills to boost your incomes dramatically and speedily at a cost affordable. SSCE is required for admission. The training will cover Entrepreneurship Psychology, Business Strategies and Etiquette, Management, Communication, Event Management, Branding, Marketing and Business Mentorship.</p>
      <p><a href={"/Register"} className="btn btn-primary mb10">Register</a></p>
    </div>
  </section>
  <Footer />
        </>
    );
}

export default About;